<template>
  <el-main>
    <el-button style="margin-bottom: 15px" icon="el-icon-plus" type="primary" size="small" @click="$router.push('/extension/activity/addActivity')">添加活动</el-button>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="活动名称：">
        <el-input size="small" v-model="activity_name"></el-input>
      </el-form-item>
      <el-form-item label="报名状态：">
        <el-select size="small" v-model="activity_status">
          <el-option label="全部" value=""></el-option>
          <el-option label="未开始" :value="-1"></el-option>
          <el-option label="报名中" :value="1"></el-option>
          <el-option label="已结束" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示：">
        <el-select size="small" v-model="is_show">
          <el-option label="全部" value=""></el-option>
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column width="120" align="center">
        <template #header>
          <div class="edit">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          <el-input type="number" :min="0" size="small" v-model="row.sort" @change="quickEdit(row, 1)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="picture" label="列表图" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 100px; height: 70px" :src="row.cover_picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="activity_name" label="活动名称" align="center"></el-table-column>
      <el-table-column label="虚拟浏览量" align="center" width="120">
        <template v-slot="{ row }">
          <el-input type="number" :min="0" size="small" v-model="row.virtual_page_view" @change="quickEdit(row, 2)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="activity_status" label="报名状态" align="center" width="120">
        <template v-slot="{ row }">{{ row.activity_status == 1 ? '报名中' : row.activity_status == 2 ? '已结束' : '未开始' }}</template>
      </el-table-column>
      <el-table-column prop="is_show" label="是否显示" align="center">
        <template v-slot="{ row }">
          <el-switch v-model="row.is_show" @change="quickEdit(row, 3)" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="$router.push({ path: '/extension/activity/editActivity', query: { id: row.id } })" size="small" type="text">编辑</el-button>
          <el-button @click="del(row)" size="small" type="text">删除</el-button>
          <el-button type="text" size="small" @click="toLookSchedule(row)">查看赛程</el-button>
          <el-button type="text" size="small" @click="toMonthlyFinals(row)">月决赛</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      activity_name: '',
      is_show: '',
      page: 1,
      rows: 10,
      list: [],
      total_number: 0,
      activity_status: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 去查看当前活动的赛程
    toLookSchedule(data) {
      this.$router.push('/extension/activity/scheduleList?activity_id=' + data.id);
    },
    // 去查看月决赛
    toMonthlyFinals(data) {
      this.$router.push('/extension/activity/monthlyFinals?activity_id=' + data.id);
    },
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    searchClear() {
      this.activity_name = '';
      this.is_show = '';
      this.page = 1;
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    getList() {
      this.$axios
        .post(this.$api.sports.activityList, {
          page: this.page,
          rows: this.rows,
          activity_name: this.activity_name,
          is_show: this.is_show,
          activity_status: this.activity_status,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            list.map(item => {
              item.award = JSON.parse(item.award);
              item.banner_picture = JSON.parse(item.banner_picture);
            });
            this.list = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    quickEdit(row, type) {
      row.sort = Number(row.sort);
      row.virtual_page_view = Number(row.virtual_page_view);
      let obj = {
        id: row.id,
      };
      if (type == 1) obj.sort = row.sort;
      if (type == 3) obj.status = row.status;
      if (type == 2) obj.virtual_page_view = row.virtual_page_view;
      this.$axios.post(this.$api.sports.quickEdit, obj).then(res => {
        if (res.code == 0) {
          console.log(res);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del(row) {
      this.$confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.sports.delActivity, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
  }
}
</style>
